import React, { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import { Wrapper } from "@googlemaps/react-wrapper";
import MapBlock from "./partials/MapBlock/Index";
import Marker from "./partials/Marker";

import "./styles.scss";
import { StoreLocatorInterface, Store } from "web/common/src/types/StoreLocatorTypes";
import StoreButton from "./partials/StoreButton";
import StoreDetails from "./partials/StoreDetails";

const StoreLocator: FunctionComponent<StoreLocatorInterface> = ({
  headline,
  ctaAllStoresButton,
  labelNoPlaces,
  labelFiltered,
  labelAll,
  labelMap,
  searchButton,
  inputPlaceholder
}) => {
  const [zipCodeField, setZipCodeField] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');
  const [clicks, setClicks] = useState<google.maps.LatLng[]>([]);
  const [zoom, setZoom] = useState(4); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: -15.7083962,
    lng: -53.7702691,
  });

  const [geoCoder, setGeoCoder] = useState<google.maps.Geocoder>();
  const [filteredStores, setFilteredStores] = useState<Store[]>([]);

  const [uf, setUf] = useState<string>();
  const [minLat, setMinLat] = useState<number>();
  const [maxLat, setMaxLat] = useState<number>();
  const [minLng, setMinLng] = useState<number>();
  const [maxLng, setMaxLng] = useState<number>();

  const [showFilteredStores, setShowFilteredStores] = useState<boolean>(false);
  const [showFilteredStoresBtn, setShowFilteredStoresBtn] = useState<boolean>(false);
  const [showMap, setShowMap] = useState<boolean>(false);
  const [showMapBtn, setShowMapBtn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getGeocoder = async () => {
    const geocodinglib = await google.maps.importLibrary("geocoding");

    const geocoder = new geocodinglib.Geocoder();
    setGeoCoder(geocoder);

    if(zipCode === '') {
      setCenter({
        lat: -15.7083962,
        lng: -53.7702691,
      });

      setUf(undefined);

      setZoom(4);
      setFilteredStores([]);

      setShowMapBtn(false);
      setShowFilteredStores(false);
      setShowFilteredStoresBtn(false)
    }

    if(geocoder && zipCode !== '') {
      geocoder.geocode({ 'address': zipCode, componentRestrictions: {
        country: 'BR'
      } }).then(({ results }) => {
        const searchedCenter = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
        
        setCenter(searchedCenter);
        setZoom(15);

        return results[0].address_components;
      })
      .then((address) => {
        const uf = address.find(item => {
          return item.types.includes("administrative_area_level_1");
        });

        setUf(uf.short_name);
      })
      .catch((e) => {
        console.error('erro', e);
      });
    }
  }

  useEffect(() => {
    getGeocoder();
  }, [zipCode]);

  const fetchStores = async () => {
    const urlBase = "https://rexona-studio-br.netlify.app";

    if(uf && minLng) {
      setIsLoading(true);

      const url = `${urlBase}/.netlify/functions/stores?uf=${uf}&minLat=${minLat}&maxLat=${maxLat}&minLng=${minLng}&maxLng=${maxLng}`;

      await fetch(url, { method: "GET", })
      .then(response => response.json())
      .then(stores => {
        setFilteredStores([...stores.content]);
      })
      .then(() => {
        setShowMapBtn(true);
        setShowFilteredStores(true);
        
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
    }
  }

  useEffect(() => {
    fetchStores();
  }, [minLng]);

  const setBounds = async (bounds: google.maps.LatLngBounds) => {
    setMaxLat(bounds.getNorthEast().lat());
    setMaxLng(bounds.getNorthEast().lng());
    setMinLat(bounds.getSouthWest().lat());
    setMinLng(bounds.getSouthWest().lng());
  }

  const onIdle = async (map: google.maps.Map) => {
    setZoom(map.getZoom()!);
    setCenter(map.getCenter()!.toJSON());

    const bounds: google.maps.LatLngBounds | undefined = map.getBounds();

    if(bounds) {
      await setBounds(bounds);
    }
  };

  const trackZipCodeField = (event: ChangeEvent<HTMLInputElement>) => {
    setZipCodeField(event.target.value);
  }

  const updateZipCode = () => {
    setShowMap(false);
    setZipCode(zipCodeField);
  }

  const onClick = (e: google.maps.MapMouseEvent) => {
    // avoid directly mutating state
    setClicks([...clicks, e.latLng!]);
  };
  
  const handleFilteredStores = () => {
    setShowFilteredStores(true);
    setShowMap(false);
    setShowFilteredStoresBtn(false);
    setShowMapBtn(true);
  }

  const handleMap = () => {
    setShowFilteredStores(false);
    setShowMap(true);
    setShowFilteredStoresBtn(true);
  }

  return (
    <section className={`store-locator`} data-testid="store-locator">
      <Container>
        {headline && (
          <h2 className="store-locator-headline" id="anchor">
            {headline.primaryText && <span className="store-locator-headline-p">{headline.primaryText}</span>}
            {headline.secondaryText && <span className="store-locator-headline-s">{headline.secondaryText}</span>}
            {headline.terciaryText && <span className="store-locator-headline-t">{headline.terciaryText}</span>}
          </h2>
        )}
      </Container>

      <div className="store-locator-input-wrapper">
        <Container>
          <label htmlFor="" className="store-locator-input">
            <input
              type="text"
              name="zip-code"
              value={zipCodeField}
              placeholder={inputPlaceholder ? inputPlaceholder : "*Informe seu CEP"}
              onChange={event => trackZipCodeField(event)}
            />
          </label>

          <div className="store-locator-button-wrapper">
            <button className="store-locator-button" onClick={updateZipCode}>
              {searchButton ? searchButton : "Buscar"}
            </button>
          </div>

          {!isLoading && showMapBtn && filteredStores.length > 0 && <StoreButton handleClick={handleMap} label={labelMap ? labelMap : "Ver Mapa"} />}
        </Container>
      </div>

      <div className={showMap ? "store-locator-map open" : "store-locator-map"}>
        <Container>
          <Wrapper apiKey={"AIzaSyDgpRnMFee5_GFm7pbX_PiuiAg_R_8oCSs"}>
            <MapBlock
              center={center}
              zoom={zoom}
              onClick={onClick}
              onIdle={onIdle}
            >
              { filteredStores.length > 0 && filteredStores.map((store, index) => {
                const storePosition = {
                  lat: parseFloat(store.lat),
                  lng: parseFloat(store.lng),
                };
                return <Marker key={index} position={storePosition} />;
              })}
            </MapBlock>
          </Wrapper>
        </Container>
      </div>

      {!isLoading && showFilteredStores && filteredStores.length > 0 && (
        <StoreDetails title={labelFiltered ? labelFiltered : "Lojas Encontradas"} stores={filteredStores} />
      )}

      {!isLoading && showFilteredStores && filteredStores.length === 0 && (
        <div className="store-locator-noplaces">
          <Container>
            <div className="store-locator-noplaces-wrapper">
              <p>{labelNoPlaces ? labelNoPlaces : "Nenhuma loja encontrada. Tente com outro CEP. ;)"}</p>
            </div>
          </Container>
        </div>
      )}

      {isLoading && (
        <div className="store-locator-noplaces">
          <Container>
            <div className="store-locator-noplaces-wrapper">
              <p>Buscando lojas...</p>
            </div>
          </Container>
        </div>
      )}

      {showFilteredStoresBtn && filteredStores.length > 0 && (
        <StoreButton handleClick={handleFilteredStores} label={labelFiltered ? labelFiltered : "Lojas Encontradas"} />
      )}

      <StoreButton url={ctaAllStoresButton.asset.url} navigate={true} label={labelAll ? labelAll : "Lojas Participantes"} />

      <div className="store-locator-btn-contact">
        <a href="/br/fale-conosco/" target="_blank">
          <span>clique aqui e</span> fale conosco
        </a>
      </div>
    </section>
  );
};

export default StoreLocator;
