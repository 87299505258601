import React, { FunctionComponent } from "react";
import { Link } from "web-common/src/components/Link";
interface StoreButtonType {
    handleClick?: () => void;
    label: string;
    navigate?: boolean;
    url?: string;
}

const StoreButton: FunctionComponent<StoreButtonType> = ({
    handleClick,
    label,
    navigate = false,
    url
}) => {
    return (
        <div className="store-locator-btn-participants">
            { !navigate
                ? <button className="store-locator-button" onClick={handleClick}>{label}</button>
                :  url ? <Link to={url} target="_blank">{label}</Link> : null }
        </div>
    )
}

export default StoreButton;