import React from "react";
import { Container } from "react-bootstrap";

const StoreDetails = ({
    title,
    stores
}) => {
    return (
        <div className="store-locator-places-wrapper">
          <h3>{title}</h3>
          
          <Container>
            <ul className="store-locator-places">
              {stores.map((item, index) => (
                <li className="store-locator-place" key={index}>
                  <strong>Loja:</strong>
                  <p>{item.id}</p>
                  <strong>Rua:</strong>
                  <p>{item.rua}</p>
                  <strong>Bairro:</strong>
                  <p>{item.b}</p>
                  <strong>Cidade:</strong>
                  <p>{item.c}</p>
                </li>
              ) )}
            </ul>
          </Container>
        </div>
    )
}

export default StoreDetails;