import React, { FC, useEffect, useRef, useState } from "react";
import { createCustomEqual } from 'fast-equals';
import { isLatLngLiteral } from '@googlemaps/typescript-guards';

import { MapProps } from "web/common/src/types/StoreLocatorTypes";

const MapBlock: FC<MapProps> = ({
  onClick,
  onIdle,
  children,
  style,
  ...options
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();

    const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
      if (
        isLatLngLiteral(a)
        // eslint-disable-next-line no-undef
        || a instanceof google.maps.LatLng
        // eslint-disable-next-line no-undef
        || b instanceof google.maps.LatLng
      ) {
        // eslint-disable-next-line no-undef
        return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
      }
      // TODO extend to other types
      // use fast-equals for other objects
      return deepEqual(a, b);
    });

    const useDeepCompareMemoize = (value: any) => {
      const ref = useRef();
    
      if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
      }
      return ref.current;
    }

    const useDeepCompareEffectForMaps = (callback: any, dependencies: any) => {
      useEffect(callback, dependencies.map(useDeepCompareMemoize));
    }

    useDeepCompareEffectForMaps(() => {
      if (map) {
        map.setOptions(options);
      }
    }, [map, options]);

    useEffect(() => {
      if (map) {
        ["click", "idle"].forEach((eventName) =>
          google.maps.event.clearListeners(map, eventName)
        );
    
        if (onClick) {
          map.addListener("click", onClick);
        }
    
        if (onIdle) {
          map.addListener("idle", () => onIdle(map));
        }
      }
    }, [map]);

    useEffect(() => {
      if (ref.current && !map) {
        setMap(new window.google.maps.Map(ref.current, {}));
      }
    }, [ref, map]);

    return (
      <>
        <div ref={ref} style={style} id="map" />

        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            // set the map prop on the child component
            // @ts-ignore
            return React.cloneElement(child, { map });
          }
        })}
      </>
    );
  }

export default MapBlock;